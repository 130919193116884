import React from 'react';
import { compose } from 'recompose';
import classNames from 'classnames';
import { I } from '../common/v5/config';
import Button from './Button';
import update from 'immutability-helper';
import { withIdAttachedOnClick, withMountWhenShow } from './hocs';

function createWithMaxDimension(offsetWidth, offsetHeight) {
	if (!offsetWidth) {
		offsetWidth = 0;
	}
	if (!offsetHeight) {
		offsetHeight = 0;
	}
	const wantedWidth = `calc(100% - ${offsetWidth}px)`
		, wantedHeight = `calc(100% - ${offsetHeight}px)`
		, wantedStyle = {
			width: wantedWidth
			, maxWidth: wantedWidth
			, height: wantedHeight
			, maxHeight: wantedHeight
		}
		;
	return Component => ({ style, ...props }) => {
		if (!style) {
			style = wantedStyle
		} else {
			style = update(style, { $merge: wantedStyle });
		}
		return <Component {...props} style={style} />;
	};
}

function createWithMaxDimSameOffset(offset) {
	return createWithMaxDimension(offset, offset)
}

function createWithMinDim(width, height) {
	if (!width) {
		width = 0;
	}
	if (!height) {
		height = 0;
	}
	const wantedStyle = {
		width
		, minWidth: width
		, height
		, minHeight: height
	}
		;
	return Component => ({ style, ...props }) => {
		if (!style) {
			style = wantedStyle
		} else {
			style = update(style, { $merge: wantedStyle });
		}
		return <Component {...props} style={style} />;
	};
}

function createWithPopupWrapper(WrapperTag) {
	return Component => ({ onClick, onClose, show, ...props }) => (
		<WrapperTag onClick={onClick} onClose={onClose} show={show}>
			<Component {...props} />
		</WrapperTag>
	);
}

const Border = ({ children, className, ...props }) => (
	<div className={className} {...props}>{children}</div>
);

const CloseButton = ({ onClick, ...props }) => (
	<div
		className="popup-close"
		onClick={onClick}
		data-qa-id={props["data-qa-id"]}
	>
		<i className="icon-times" />
	</div>
);

const MinimizeButton = ({ onClick, icon = 'icon-fullscreen-end',  ...props }) => (
	<div
		className="popup-minimize"
		onClick={onClick}
		data-qa-id={props["data-qa-id"]}
	>
		<i className={icon} />
	</div>
);

const withCloseFrame = Component => ({ children, className, onClose, onMinimize, minimizeIcon, style, ...props }) => (
	<Component className={classNames(className, "popup-inner")} style={style}>
		{children}
		{
			onMinimize &&
				<MinimizeButton icon={minimizeIcon} onClick={onMinimize} data-qa-id={props["data-qa-id"]+"-minimize"} />
		}
		<CloseButton
			onClick={onClose}
			data-qa-id={props["data-qa-id"]+"-close"}
		/>
	</Component>
);

function withCloseableWithClosePrefix(OuterComponent, InnerComponent, prefix) {
    return ({ extraClass, innerClass, children, onMinimize, minimizeIcon, onClose, ...props }) => {
        const customStyle = extraClass === "knowledge-base-backdrop" ? {} : { zIndex: 1000 };
        return (
            <OuterComponent className={classNames(extraClass, "popup open")} style={customStyle}>
                <InnerComponent
                    className={innerClass}
                    onClose={onClose}
                    onMinimize={onMinimize}
                    minimizeIcon={minimizeIcon}
                    data-qa-id={prefix + props["data-qa-id"]}
                    style={{ zIndex: 100 }}
                >
                    {children}
                </InnerComponent>
                <div
                    className="popup-inner-back"
                    onClick={onClose}
                    data-qa-id="popup-inner-back-select"
                    style={{ position: "absolute", width: "100%", height: "100%", zIndex: 99 }}
                ></div>
            </OuterComponent>
        );
    };
}

const withBorderPopupAndFixedPrefixBase = innerComponent => withCloseableWithClosePrefix(Border, innerComponent, "close-");

const withBorderPopupAndFixedPrefix = compose(
	withMountWhenShow
	, withBorderPopupAndFixedPrefixBase
);

const PopupPage = compose(
	withBorderPopupAndFixedPrefix
	, withCloseFrame
)("div");

export default PopupPage;

const BottomBlock = ({ children }) => (
	<div className="bottom-block">{children}</div>
);

function withPopupBottomBlock(PopupComponent, BottomBlockComponent, buttonsFieldName) {
	return ({ children, ...props }) => {
		const data = props[buttonsFieldName];
		if (!data || !data.length) {
			return <PopupComponent {...props}>{children}</PopupComponent>;
		}
		return (
			<PopupComponent {...props}>
				<div className="message-bottom-block">
					<div className="message-block">{children}</div>
					<BottomBlockComponent {...props} />
				</div>
			</PopupComponent>
		);
	};
}

const withBottomButtons = (BorderComponent, ButtonComponent) => ({ buttons, onClick: genericOnClick, ...props }) => {
	let renderButtons = [];
	$.each(buttons, (i, { color, disabled, onClick, id, text, title }) => {
		let style, handleClick;
		if (typeof disabled === "undefined") {
			disabled = props[id + "Disabled"];
		}
		if (i > 0) {
			style = notFirstMarginLeft
		}
		if (typeof onClick === "function") {
			handleClick = onClick;
		} else {
			handleClick = genericOnClick;
		}
		renderButtons.push(
			<ButtonComponent
				key={i + ""}
				className="bottom-button"
				color={color}
				disabled={disabled}
				id={id}
				onClick={handleClick}
				text={text}
				title={title}
			/>
		);
	});
	return <BorderComponent>{renderButtons}</BorderComponent>;
};

const withBottomBlock = ButtonComponent => withBottomButtons(
	BottomBlock
	, ButtonComponent
);

const BottomBlockIdButtons = compose(
	withBottomBlock
	, withIdAttachedOnClick
)(Button);

const withBottomBlockIdButtons = PopupComponent => withPopupBottomBlock(
	PopupComponent
	, BottomBlockIdButtons
	, "buttons"
);

export const PopupWithButtons = withBottomBlockIdButtons(PopupPage);

const withIdButtonCloseFrame = compose(
	withBottomBlockIdButtons
	, withBorderPopupAndFixedPrefix
	, withCloseFrame
);

export const popupWithMaxDim = offset => compose(
	withIdButtonCloseFrame
	, createWithMaxDimSameOffset(offset)
)("div");

const popupWithMinDim = (width, height) => compose(
	withIdButtonCloseFrame
	, createWithMinDim(width, height)
)("div");

export const createWithPopupMinDim = (width, height) => createWithPopupWrapper(popupWithMinDim(width, height));
